import { useState, useEffect } from "react";
import { validateEmail, validateMobileNumber } from "../tools/validator";
import {createContact} from '../http'

const ContactUs = () => {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState({field: null, message: null});

    const [submitting, setSubmitting] = useState(false);
    const [success, setSuccess] = useState('')

    const handleSubmit = async () => {
        setError({field: null, message: null});
        if(!name) return setError({field: 'name', message: 'Name is required'});
        if(!mobile) return setError({field: 'mobile', message: 'Mobile is required'});
        if(!validateMobileNumber(mobile)) return setError({field: 'mobile', message: 'Invalid mobile number'})
        if(!email) return setError({field: 'email', message: 'Email is required'});
        if(!validateEmail(email)) return setError({field: 'email', message: 'Invalid email'});

        setSubmitting(true)

        try {
            const {data} = await createContact({name, email, mobile, subject, message});
            console.log(data)
            if(data.success){
               setSuccess("Your message submittes successfully")
               setName('');
               setEmail('');
               setMobile('');
               setSubject('');
               setMessage('')
            }
        } catch (error) {
            console.log(error);
            setError({field: 'form', message: 'Message not sent'})
        } finally{
            setSubmitting(false)
        }
       
    }


    return (
        <div className="page-content">
              <section id="page-header" className="d-flex align-items-center">
                <div className="container">
                    <div className="page-title">
                        <h1 className="page-heading text-white">
                            Contact Us Us
                        </h1>
                    </div>
                </div>
            </section>

            <section id="contact">
                <div className="contact-top">
                    <div className="container">
                        <div className="contact-intro col-6">
                            <p className="text color-primary"><strong>Contact Us</strong></p>
                            <h1 className="primary-heading">We Are Waiting to<br/>Hear From You</h1>
                            <p className="text">Seal your roof, protect your walls, and fortify your exterior<br/>Book Now with HomeBOQ for expert Service.</p>
                        </div>
                        <div className="contact-form">
                        <div className="contact-form-inner">
                                <h2 className="accent-heading">Drop us a <span className="color-primary">message</span></h2>
                                <div className="form">
                                    <div className="mb-3">
                                       <input type="text" value={name} onChange={(e) => setName(e.target.value)} className="form-control input" placeholder="Name" />
                                       {error.field == 'name' && <p className="status error">{error.message}</p>}
                                    </div>
                                    <div className="mb-3">
                                       <input type="text" value={mobile} onChange={(e) => setMobile(e.target.value)} className="form-control input" id="Name" placeholder="Phone" />
                                       {error.field == 'mobile' && <p className="status error">{error.message}</p>}
                                    </div>
                                    <div className="mb-3">
                                       <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} className="form-control input" id="email" placeholder="Email" />
                                       {error.field == 'email' && <p className="status error">{error.message}</p>}
                                    </div>
                                    <div className="mb-3">
                                       <input value={subject} onChange={(e) => setSubject(e.target.value)} type="text" className="form-control input" id="subject" placeholder="Subject" />
                                    </div>
                                  
                                    <div className="mb-3">
                                       <textarea value={message} onChange={(e) => setMessage(e.target.value)} className="form-control input" id="Location" placeholder="Message" />
                                    </div>
                                    <div className="d-grid">
                                    <button type="button" class="btn btn-primary-main" onClick={handleSubmit}>
                                        <span>Submit</span>
                                        {submitting ? <span class="spinner-border spinner-border-sm ml-4" style={{marginLeft: '8px'}} role="status" aria-hidden="true"></span> : (<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-arrow-up-right" viewBox="0 0 16 16">
                                           <path fill-rule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z"/>
                                        </svg>)}
                                    </button>
                                    {error.field == 'form' && <p className="status error">{error.message}</p>}
                                    <p className="status success">{success}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                 <div className="contact-bottom">
                 <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d487295.22581163223!2d78.07838586924265!3d17.412077907948387!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb99daeaebd2c7%3A0xae93b78392bafbc2!2sHyderabad%2C%20Telangana!5e0!3m2!1sen!2sin!4v1725874464756!5m2!1sen!2sin" style={{border: '0'}} height={350} width='100%' allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                 </div>
            </section>

            <section id="reach">
                <div className="container">
                    <div className="row gx-5 gy-5">
                        <div className="col-6">
                            <h2 className="primary-heading">Reach To <span className="color-primary">Us</span></h2>
                            <div className="contact-card">
                                <div className="contact-card-inner">
                                    <div className="contact-icon">
                                       <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt" viewBox="0 0 16 16">
                                           <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A32 32 0 0 1 8 14.58a32 32 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10"/>
                                               <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6"/>
                                        </svg>
                                    </div>
                                    <div className="contact-card-content">
                                        <p className="text">Location</p>
                                        <h2 className="secondary-heading">Hyderabad, Telangana India</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="contact-card">
                                <div className="contact-card-inner">
                                    <div className="contact-icon">
                                       <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16">
                                          <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z"/>
                                        </svg>
                                    </div>
                                    <div className="contact-card-content">
                                        <p className="text">Email</p>
                                        <h2 className="secondary-heading">support@boq.com</h2>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-6">
                            <div className="contact-card">
                                <div className="contact-card-inner">
                                    <div className="contact-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone" viewBox="0 0 16 16">
                                           <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"/>
                                        </svg>
                                    </div>
                                    <div className="contact-card-content">
                                        <p className="text">Mobile</p>
                                        <h2 className="secondary-heading">+91 91779 38956</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="contact-card">
                                <div className="contact-card-inner">
                                    <div className="contact-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chat-dots" viewBox="0 0 16 16">
                                           <path d="M5 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0m4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2"/>
                                           <path d="m2.165 15.803.02-.004c1.83-.363 2.948-.842 3.468-1.105A9 9 0 0 0 8 15c4.418 0 8-3.134 8-7s-3.582-7-8-7-8 3.134-8 7c0 1.76.743 3.37 1.97 4.6a10.4 10.4 0 0 1-.524 2.318l-.003.011a11 11 0 0 1-.244.637c-.079.186.074.394.273.362a22 22 0 0 0 .693-.125m.8-3.108a1 1 0 0 0-.287-.801C1.618 10.83 1 9.468 1 8c0-3.192 3.004-6 7-6s7 2.808 7 6-3.004 6-7 6a8 8 0 0 1-2.088-.272 1 1 0 0 0-.711.074c-.387.196-1.24.57-2.634.893a11 11 0 0 0 .398-2"/>
                                        </svg>
                                    </div>
                                    <div className="contact-card-content">
                                        <p className="text">Live Chat</p>
                                        <h2 className="secondary-heading">Connec With us on <span className="color-primary"><a className="color-primary" target="_blank" href="https://wa.me/+919177938956">WhatsApp</a></span></h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default ContactUs;